<template>
  <main class="kb-main" id="kb-educourse-status">
    <lxp-main-header :show-breadcrumb="true" :show-title="true" title="의무교육 현황"/>
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="educourse-list-container">
        <!-- list-top -->
        <div class="list-top pb-4">
          <div class="top-column">
            <div class="sort" style="margin-right: 15px">
                <SortButtonSelect
                  v-model="search.rsltYn"
                  v-model:toggle="toggles.rsltYn"
                  title="완료여부"
                  :options="rsltYnOptions"
                  sequence-key="key"
                  name-key="name"
                  :is-all="true"
                  :width="200"
                  @update:toggle="closeToggles(toggles, 'rsltYn')"
                  @selected="pagingFunc(1)"
                />
            </div>
          </div>
          <div class="top-column">
            <p class="title text-muted">총 {{ paging.totalCount }}건</p>
          </div>
        </div>
        <!-- //list-top -->
        <!-- status-tablelist -->
        <div class="status-tablelist">
          <div class="kb-table kb-table-bordered">
            <table>
              <colgroup>
                <col style="width:12%;">
                <col style="width:12%;">
                <col style="width:12%;">
                <col style="width:12%;">
                <col style="width:auto;">
                <col style="width:10%;">
                <col style="width:10%;">
              </colgroup>
              <thead>
                  <tr>
                    <th><span class="th-title">직원번호</span></th>
                    <th><span class="th-title">직급</span></th>
                    <th><span class="th-title">성명</span></th>
                    <th><span class="th-title">부점명</span></th>
                    <th><span class="th-title">과정명</span></th>
                    <th><span class="th-title">진도율</span></th>
                    <th><span class="th-title">완료여부</span></th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                    <td><strong class="td-text">{{ item.jbgdCd }}</strong></td>
                    <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                    <td><strong class="td-text">{{ item.deptNm }}</strong></td>
                    <td>
                      <strong class="td-text kb-mouse-cursor kb-hover-underline" @click="goLearn(item)">{{ item.distCrseNm }}</strong>
                    </td>
                    <td><strong class="td-text">{{ item.finishObjRate }}</strong></td>
                    <td><strong class="td-text">{{ item.rsltYn }}</strong></td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        <!-- //status-tablelist -->
      </div>
    </div>

  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import {reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {getListFunc, getValueByQuery, pushQuery, setFilterByQuery, closeToggles} from '@/assets/js/ui.init';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import {ACT_GET_DUTY_LRN_STATUS_LIST} from '@/store/modules/dept/dept';
import {timestampToDateFormat} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  name: 'DutyLrnStatus',
  components: {LxpMainHeader, CommonPaginationFront, SortButtonSelect},
  setup() {

    const route = useRoute();
    const store = useStore();
    const items = ref([]);
    const {showMessage} = useAlert();
    const router = useRouter();

    const search = reactive({
          rsltYn: '',
    });
    const toggles = reactive({
          rsltYn: false,
    });

    const paging = reactive({
      pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
      pageSize: 10,
      totalCount: 0,
    });

    const rsltYnOptions = [{key:'Y', name:'완료'}, {key:'N', name:'미완료'}];

    const getDeptDutyLrnSttList = () => {
        getListFunc(`dept/${ACT_GET_DUTY_LRN_STATUS_LIST}`, {lrnerId: store.state.auth.session.lrnerId, ...search, ...paging}, items, paging, () => {

            items.value = items.value.map(x => ({...x}));
        });
    };

    const pagingFunc = (pageNo) => {
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
      paging.pageNo = pageNo;
    };

    const goLearn = (item) => {
      console.log(item);
      // TrainProceeding
      if (item.bgngDt > new Date().getTime()) {
        showMessage(timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') + ' 부터 연수를 하실 수 있습니다.');
      } else if (item.endDt < new Date().getTime()) {
        showMessage('해당 연수는 ' + timestampToDateFormat(item.endDt, 'yyyy.MM.dd') + '일 부로 종료되었습니다.');
      } else {
        router.push({name: 'LearnMain', params: {distCrseSn: item.distCrseSn, view: 'home'}});
      }
    }

    getDeptDutyLrnSttList();

    watch(() => route.query, () => {
        setFilterByQuery(search, route.query);
        getDeptDutyLrnSttList();
    });

    return {
      items,
      paging,
      pagingFunc,
      search,
      toggles,
      rsltYnOptions,
      closeToggles,
      goLearn
    };
  },
};
</script>
